import React, {useState} from "react";
//import HomeFooter from "./HomeFooter";
import librarian from "../assets/images/young-librarian.jpg";
import bookshelf from "../assets/images/old-bookshelf.jpg";
import roadmap from "../assets/images/roadmap.jpg";
import contact from "../assets/images/contactus.jpg";
import Hero from "./landing/Hero";
import Hero2 from "./landing/Hero2";
import WorkFlows from "./landing/WorkFlows";
import Features from "./landing/Features";
import Cta from "./landing/Cta";
import Footer from "./landing/Footer";
function Home() {

  //const library = `url("${librarian}")`;
  //const book = `url("${bookshelf}")`;


  return (
    <div className="h-screen" >
      <Hero />
      <WorkFlows />




        <Hero2 />

        <Features />

        <Cta />  

        <Footer />

    </div>

  );
}
export default Home;