import logo from "../../assets/images/librarylogor.png";



export default function Logo() {
    return (
        <a className="inline-flex shrink-0" href="/">
            <img src={logo} alt="Cruip Logo" width={32} height={32} />
        </a>

    );
  }