import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';


function TutorialCard({title, id}){
  return (
    <>
      <div className="p-4 md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="flex rounded-lg h-full  bg-gray-100 dark:bg-slate-800  p-8 flex-col">
          <div className="flex items-center mb-3">
            <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-700 text-white flex-shrink-0">
            <ion-icon name="pencil-outline"></ion-icon>

            </div>
            <h1 className="text-base title-font font-medium">{title}</h1>
          </div>
          <div className="flex-grow">
            <a href={"/tutorials/tutorial/"+id} className="mt-3 text-indigo-500 inline-flex items-center font-bold">Read Tutorial
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}



function TutorialsCategory() {
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

    // Get a specific parameter
    let { category } = useParams();

  useEffect(() => {
    fetch(`https://goldfish-app-asx3v.ondigitalocean.app/tutorials/category/${category}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-6 py-24 flex justify-center items-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-6 py-24 flex justify-center items-center">Loading...</div>;
  } 
  else if (items === null  || items.length === 0) {
    return <div className="px-6 py-24 flex justify-center items-center">
        There has yet to be a tutorial in this category. We are working hard on it. Please come back later  !</div>;
  }
  
  else {


    return (
      <>
        <br /><br />
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={`Access thousands of detailed ${category} tutorials`}  />
        </Helmet>
        <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href="/tutorials" class="btn  btn-ghost px-4 text-sm text-sky-600"><ion-icon name="arrow-back"></ion-icon>&emsp; Tutorials</a>
        </div>
        <div className="w-full justify-center items-center">

            <div className=" mt-6 container mx-auto flex justify-center items-center px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>{category}</b></h2>

            </div>

            <div className="px-6 py-6 flex justify-center items-center">
              <input  type="text" placeholder="Search by title" className="input-ghost-secondary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
            </div>

    <div className="container px-5 py-5 mx-auto flex flex-wrap">

    {items.filter((quiz) => //items here
              {
                if(searchItem === "") {
                  return quiz;
                }
                else if 
                (quiz.Title.toLowerCase().includes(searchItem.toLowerCase()) ) {
                  return quiz;
                }
                else return null
                
              }).map(quiz => 
          <TutorialCard key={quiz.Id} 
          id = {quiz.Id}
          title={quiz.Title}  >

        </TutorialCard>)}
    </div>


        </div>

      
      </>

    );}
  }
  export default TutorialsCategory;