import React from "react";
function Disclaimer() {
    return (
      <>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> Disclaimers</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                <h2><b><u>Permissions</u></b></h2>
                  <p>
                  The books available for download on this website come from various sources. 
                  We have selected the books and organized them in categories so you can easily find the book you seek. 
                  Since We have no intention to violate copyrights and illegally distribute the hard work of authors, we only select eBooks that are in the public domain in the US. 
                  Nobody can grant or withhold permission to distribute those books. 
                  <a className="text-blue-400 hover:text-blue-600" href="https://www.gutenberg.org/policy/permission.html" target="_blank" rel="noreferrer"> Learn more here</a>.
                  </p>
                <h2><b><u>Affiliate Links</u></b></h2>
                  <p>
                  Some of the links contained on this website are affiliate links. 
                  Arfusoft has established strong partnerships with some companies, so we promote their products. 
                  As a result, we may receive a commission if you click on the link and purchase from the affiliate. 
                  This is of no cost to you. We only <a className="text-blue-400 hover:text-blue-600" href="/products"> recommend products and services</a> that we know or trust to be of high quality, whether an affiliate relationship exists or not. 
                  Affiliate links are tracked using <a className="text-blue-400 hover:text-blue-600" href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noreferrer"> cookies</a>.
                  </p>
                  <h2><b><u>Products description</u></b></h2>
                  <p>
                  When we recommend a product, we describe it as accurately as possible. 
                  However, we cannot guarantee that the products and prices will be completely accurate. 
                  Likewise, we cannot guarantee their uses for your particular circumstances and purposes. 
                  Please do not accept that the product descriptions contained on this website are entirely accurate, current, or error-free. 
                  Occasionally, we may correct errors in pricing and merchandise descriptions.
                  </p>

                  <p>For more information, please get in touch with us by email at <a className="text-blue-400 hover:text-blue-600" href="mailto:contact@arfusoft.com">contact@arfusoft.com</a> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default Disclaimer;