import {React} from "react";




function Products() {
  return (
    <>
        <div className="py-2 flex justify-center items-center  bg-base-100 mainDiv" >
          
            <div className="py-4 lg:py-6">

                <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
                    <br /><br /><h2 className="flex justify-center items-center"><b>Products recommendations</b></h2>
                    <p className="text-black-500 dark:text-white">
                    Below, you will find the products we recommend. We only recommend products and services that we know or trust to be of high quality.
                    </p>
                    <h2 className="flex  justify-center items-center text-purple-400"><b><u>Books / TextBooks</u></b></h2>
                    <div className="flex flex-wrap justify-center items-center">
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=c9f0d186fd9f633aafeb80e87c27817a&camp=1789&creative=9325&index=books&keywords=bass guitar"><button className="btn text-white bg-red-500 hover:bg-purple-700">Show me Bass Guitar books</button></a>
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=8429d6e7c2a4e8a8d7bc28cb6b60af53&camp=1789&creative=9325&index=books&keywords=acoustic guitar"><button className="btn text-white bg-purple-500 hover:bg-purple-700">Show me Acoustic Guitar books</button></a>
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=8429d6e7c2a4e8a8d7bc28cb6b60af53&camp=1789&creative=9325&index=books&keywords=learn piano"><button className="btn text-white bg-blue-500 hover:bg-purple-700">Show me Piano books</button></a>
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=8429d6e7c2a4e8a8d7bc28cb6b60af53&camp=1789&creative=9325&index=books&keywords=drums"><button className="btn text-white bg-orange-500 hover:bg-purple-700">Show me Drums books</button></a>
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=8429d6e7c2a4e8a8d7bc28cb6b60af53&camp=1789&creative=9325&index=books&keywords=brass instrument"><button className="btn text-white bg-green-500 hover:bg-purple-700">Show me Brasses books</button></a>
                        <a  className="px-2" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=arfusoft-20&linkCode=ur2&linkId=8429d6e7c2a4e8a8d7bc28cb6b60af53&camp=1789&creative=9325&index=books&keywords=string instrument"><button className="btn text-white bg-teal-500 hover:bg-purple-700">Show me Strings books</button></a>
                    </div><br />

                    <section className="text-gray-600 dark:text-white body-font">
                        <div className="container  px-5 py-4 mx-auto">
                            <div className="flex flex-wrap -m-4">





                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </>
  );
  }
  export default   Products;