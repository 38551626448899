import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import math from "../../assets/images/math.jpg";
import biology from "../../assets/images/biology.jpg";
import statistics from "../../assets/images/statistics.jpg";
import celebrities from "../../assets/images/celebrities.jpg";
import law from "../../assets/images/law.jpg";
import psychology from "../../assets/images/psychology.jpeg";
import politics from "../../assets/images/politics.jpg";
import geography from "../../assets/images/geography.jpg";
import anthropology from "../../assets/images/anthropology.jpg";
import history from "../../assets/images/history.jpg";
import philosophy from "../../assets/images/philosophy.jpg";
import economy from "../../assets/images/economy.jpg";
import archaeology from "../../assets/images/archaeology.jpg";
import sociology from "../../assets/images/sociology.jpg";
import finance from "../../assets/images/finance.jpg";
import religion from "../../assets/images/religion.jpg";
import physics from "../../assets/images/physics.jpg";
import chemistry from "../../assets/images/chemistry.jpg";
import geology from "../../assets/images/geology.jpg";
import astrology from "../../assets/images/astrology.jpg";
import music from "../../assets/images/music.jpg";
import dance from "../../assets/images/dance.jpg";
import film from "../../assets/images/film.jpg";
import theatre from "../../assets/images/theatre.jpg";
import literature from "../../assets/images/literature.jpg";
import other from "../../assets/images/other.jpg";


function QuizCategoryCard({id, image, category}){
  return (
    <>
      <div className="md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="card shadow w-80 m-4">
          <figure>
            <img src={image} alt="" />
          </figure>
          <div className="card-body flex items-center" >
            <img className="avatar" src={image} alt="" />
              <h2 className="card-title"> {category}</h2>
              { (id%2 === 0) &&
                <a  className="w-full btn bg-purple-700 hover:bg-purple-900 text-white btn-md" href={`/quizzes/${category}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
              { (id%2 === 1) &&
                <a  className="w-full btn bg-blue-700 hover:bg-blue-900 text-white btn-md" href={`/quizzes/${category}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
          </div>
        </div>

      </div>
    </>
  );
}



function QuizzesMain() {
  var items = [
    //{Id:1, Category:"Math", Image:math},
    {Id:2, Category:"Biology", Image:biology},
    //{Id:3, Category:"Statistics", Image:statistics},
    {Id:4, Category:"Celebrities", Image:celebrities},
    //{Id:5, Category:"Law", Image:law},
    //{Id:6, Category:"Psychology", Image:psychology},
    {Id:7, Category:"Political Science", Image:politics},
    //{Id:8, Category:"Geography", Image:geography},
    {Id:9, Category:"Anthropology", Image:anthropology},
    //{Id:10, Category:"History", Image:history},
    //{Id:11, Category:"Philosophy", Image:philosophy},
    //{Id:12, Category:"Economy", Image:economy},
    //{Id:13, Category:"Archaeology", Image:archaeology},
    {Id:14, Category:"Sociology", Image:sociology},
    //{Id:15, Category:"Finance", Image:finance},
    //{Id:16, Category:"Religion", Image:religion},
    //{Id:17, Category:"Physics", Image:physics},
    //{Id:18, Category:"Chemistry", Image:chemistry},
    //{Id:19, Category:"Geology", Image:geology},
    //{Id:20, Category:"Astrology", Image:astrology},
    //{Id:21, Category:"Music", Image:music},
    //{Id:22, Category:"Dance", Image:dance},
    //{Id:23, Category:"Film", Image:film},
    //{Id:24, Category:"Theatre", Image:theatre},
    //{Id:25, Category:"Literature", Image:literature},
    //{Id:26, Category:"Other", Image:other},
  ];
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Instantly get access to thousands of quizzes." />
          <meta property="og:description" content="Access quizzes in science, literature, art, celebrities, etc."/>
        </Helmet>
        <section className="relative">
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="border-t py-12 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">

          <div className=" py-12 container mx-auto px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Quizzes</b></h2>
            <p>While tests remain a popular way to test knowledge and improve retention of information,  
              they also offer many other benefits that make them a valuable learning tool. 
              Here are just a few of the ways  taking tests can be beneficial: </p>

            <p><b>Improved memory</b>: 
            When you take a test, you actively retrieve information from your memory, 
            strengthening the connections between neurons in your brain. 
            This helps you recall content better in the short term and improves your long-term retention of information.</p>
            <p><b>Identify strengths and weaknesses</b>: 
            Tests can help you determine which areas you are good at and which areas you need to focus on. 
            This is especially helpful for students preparing for exams, 
            as they can focus their studies on the areas they need to improve the most. </p>
            <p><b>Encourage active learning</b>: 
            Passive learning, like reading or watching lectures, 
            can be less effective than actively engaging with the material. 
            Tests allow active learning by forcing you to think critically about the content and apply what you've learned. </p>
            <p><b>Self-assessment</b>: 
            Tests are a helpful way to evaluate your understanding of the material. 
            Consistently answering questions incorrectly may indicate that you must review the material more thoroughly.</p>
            <p>Conversely, consistently answering questions correctly will increase your confidence and motivation to continue studying. 
              Test Retention: Tests are a valuable tool for long-term test retention. 
              By taking tests regularly, you can track your progress and see how well you've retained the content. 
              This is especially useful for students preparing for exams, as it helps you identify areas that need more thorough revision.</p>
            </div>
          <div className="py-5 mx-auto flex flex-wrap">
            {items.map(quiz => 
                <QuizCategoryCard key={quiz.Id} 
                id = {quiz.Id}
                category={quiz.Category}
                image={quiz.Image}
                >
              </QuizCategoryCard>)}
          </div>
          </div>
        </div>
      </section>

      </>

    );
  }
  export default QuizzesMain;