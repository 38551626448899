import {React, useState} from "react";
import {Helmet} from "react-helmet";


function RhymesListDisplay ({id, word}){
        return (
            <>
            <p className=""  key={id}>{word}</p> 
            <hr></hr>
            </>
        )


  }


function RhymesFinder(){

    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [items, setItems] = useState([]);
    const [haveData, setHaveData] = useState(true);



    const getRhythms =() => {
            setButtonClicked(true);
            setIsLoaded(false);
            setHaveData(true);


            const url = `https://api.datamuse.com/words?rel_rhy=${searchItem.toLowerCase()}`;
            
            fetch(url)
              .then(res => res.json())
              .then(
                (result) => {
                  setIsLoaded(true);
                  setItems(result);
                  //setLen(Object.keys(result).length);
                  if((Object.keys(result).length) ===0) {
                    setHaveData(false);
                  }
                },

                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
            );   
    }

            return(
                <div className="wrapper py-12">
                          <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Rhymes Finder"  />
        </Helmet>
                <div className="py-4 lg:py-6">
                    <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-sky-600 px-6 lg:px-12">
                      <h2 className="flex justify-center items-center"><b>Rhymes Finder</b></h2>
                        <div className="px-2 py-6 flex justify-center items-center">
                            <input  type="text" placeholder="Enter a word" className="input-ghost-secondary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
                            <div className="px-1 flex justify-center items-center">
                                <button className="btn btn-secondary" onClick={getRhythms}><ion-icon name="search-outline"></ion-icon></button>
                            </div>
                        </div>

                        <div className="flex justify-center items-center">
                        {error && `${error.message}`}
                        </div>
                        <div className="flex justify-center items-center">
                            {!isLoaded && isButtonClicked && <button className="btn btn-ghost btn-loading"></button>}
                        </div>
                        <div className="flex justify-center items-center">
                            {!haveData && isButtonClicked && `No rhyme found for "${searchItem}".`}
                        </div>
                        <div className="py-1 flex justify-center items-center">
                                <ul className="menu menu-vertical px-1 w-full  max-w-lg">
                                {items.map((wrd,index) => 
                                <RhymesListDisplay 
                                key={index} 
                                id={index} 
                                word ={wrd.word} >
                    
                                </RhymesListDisplay>
                                )}
                            </ul>

                        </div>
                    </div>
                  </div>

                </div>
        
            );
        
}
export  default  RhymesFinder;