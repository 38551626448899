import React from "react";
import treble from "../assets/images/book4.png";
import secure from "../assets/images/secure8r.png";

function ResetPass() {
    return (
      <>
            <br /><br /><br /><br />
        <div className="gradient-form flex  h-full  place-items-center items-center justify-center  p-6 dark:bg-neutral-700  ">
          <div className="flex items-center justify-center shadow">
            <div className="block rounded-lg bg-white">
              <div className="g-0 lg:flex lg:flex-wrap">

                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">

                    <div className="text-center">
                      <img className="mx-auto w-48" src={treble} alt="logo" />
                      <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">Forgot password</h4>
                    </div>

                    <form>

                      <div className="flex flex-row items-center justify-center py-2">
                        <p className="mb-0 mr-4 text-sm">Please enter your email and we'll send you a link to reset your password.</p>


                      </div>
            
                      <div className="relative mb-4 py-2" data-te-input-wrapper-init>
                        <input className="input-block input-ghost-primary input" placeholder="Email address" />
                      </div>

                      <div className="mb-12 pb-1 pt-1 text-center">
                        <button
                          className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                          type="button"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          style={{background: "linear-gradient(to right, #298cca, #2174c7, #2174c7,#298cca)"}}
                        >
                          Reset my password
                        </button>


                      </div>


                    </form>
                  </div>
                </div>


                <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none" 
                style={{background: "linear-gradient(to right, #298cca, #2174c7, #0050b0,#00336e)"}}
                >
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold"></h4>
                    <img
                  src={secure}
                  className="w-full"
                  alt="Sample image"
                />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default ResetPass;