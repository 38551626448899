import {React, useState} from "react";
import {Helmet} from "react-helmet";

function Definition ({ id, definition, example, synonyms=[], antonyms=[]}){
    return (
        <>
        { ((definition !== "") && (definition !== null) && (definition !== undefined)) &&
        <p   className="text-gray-500 dark:text-white"> 
        <span className="font-bold text-gray-500 dark:text-purple-500">
            <ion-icon className="text-gray-500 dark:text-purple-500" name="stop-circle-outline"></ion-icon> </span>  &nbsp; {definition}</p> }

        { ((example !== "") && (example !== null) && (example !== undefined)) &&
        <p  className="text-gray-500 dark:text-white"> 
        <span className="font-bold text-gray-500 dark:text-purple-500">Ex </span>  : {example}</p> }

        { ((synonyms !== "") && (synonyms !== null) && (synonyms !== undefined) && (synonyms.length !== 0)) &&
        <p  className="text-gray-500 dark:text-white"> 
        <span className="font-bold text-gray-500 dark:text-purple-500">Synonyms </span>  : {synonyms.join(", ")}</p> }

        { ((antonyms !== "") && (antonyms !== null) && (antonyms !== undefined) && (antonyms.length !== 0)) &&
        <p   className="text-gray-500 dark:text-white"> 
        <span className="font-bold text-gray-500 dark:text-purple-500">Antonyms </span>  : {antonyms.join(", ")}</p> }
           <br />
        </>
    )


}



function Meaning ({id, partofspeech, definitions=[]}){
        return (
            <>
            <p  className="text-gray-500 dark:text-white"> 
                <span className="text-purple-400 font-bold"> {partofspeech[0].toUpperCase()+partofspeech.slice(1)} </span> </p> 
            <div>
                {
                    definitions.map((def,index) => 
                        <Definition 
                        id={index}  
                        key={index}  
                        definition={def.definition} 
                        example={def.example} 
                        synonyms={def.synonyms} 
                        antonyms={def.antonyms} 
                        >
            
                        </Definition>
                    )
                }
            </div>
            </>
        )


  }

function WordDefinition ({id, word, phonetic, origin, meanings=[]}){
    return (
        <>
        <section id={id} className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col items-center">
            <div className="">
                <div>
                    <h1 className="title-font sm:text-2xl text-xl mb-1 font-medium text-sky-600">{word[0].toUpperCase()+word.slice(1)}</h1> 
                    
                </div>
                <div>
                    { ((phonetic !== "") && (phonetic !== null) && (phonetic !== undefined)) &&
                        <p className="mt-4 leading-relaxed text-black-500 dark:text-white ">
                            <ion-icon className="text-gray-500" name="volume-medium-outline"></ion-icon> &nbsp; {phonetic}</p>
                    }
                    { ((origin !== "") && (origin !== null) && (origin !== undefined)) &&
                        <p className="mb-4 leading-relaxed text-black-500 dark:text-white ">Origin: {origin}</p>
                    }
                </div><hr className="w-full mb-2"></hr>
                {
                    meanings.map((item,index) => 
                        <Meaning 
                        id={index} 
                        key={index} 
                        partofspeech ={item.partOfSpeech} 
                        definitions ={item.definitions} 
                        >
            
                        </Meaning>
                    )
                }
            </div>
            </div>
        </section>
        </>
    )
}




//https://github.com/meetDeveloper/freeDictionaryAPI
function EnDictionary(){

    const [searchItem, setSearchItem] = useState('')
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [items, setItems] = useState([]);
    const [haveData, setHaveData] = useState(true);



    const getDefinition =() => {
        //document.getElementById("defcontent").innerText='';
            setButtonClicked(true);
            setIsLoaded(false);
            setHaveData(true);
            const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${searchItem.toLowerCase()}`;
            
            fetch(url)
              .then(res => res.json())
              .then(
                (result) => {
                  setIsLoaded(true);
                  setItems(result);
                  //setLen(Object.keys(result).length);
                  if((Object.keys(result).length) ===0) {
                    setHaveData(false);
                  }
                },

                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
            );   
    }


        return(
            <div className="wrapper py-12">
          <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="English Dictionary"  />
        </Helmet>
            <div className="py-4 lg:py-6">
                <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-sky-600 px-6 lg:px-12">
                  <h2 className="flex justify-center items-center"><b>English Dictionary</b></h2>
                    <div className="px-2 py-6 flex justify-center items-center">
                        <input  type="text" placeholder="Enter a word" className="input-ghost-secondary input w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
                        <div className="px-1 flex justify-center items-center">
                            <button className="btn btn-secondary" onClick={getDefinition}><ion-icon name="search-outline"></ion-icon></button>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        {!isLoaded && isButtonClicked && <button className="btn btn-ghost btn-loading"></button>}
                    </div>
                    <div className="flex justify-center items-center">
                    {error  && `${error.message}`}
                    </div>

                    <div className="flex justify-center items-center">
                        {!haveData && isButtonClicked  && `No definition found for "${searchItem}".`}
                    </div>
                    <div id="defcontent" className="py-1 flex justify-center items-center">
                         { 
                            Array.isArray(items)?
                            items.map((i,index) => 
                                <WordDefinition 
                                id={index}
                                key={index} 
                                word ={i.word} 
                                origin ={i.origin} 
                                phonetic ={i.phonetic} 
                                meanings ={i.meanings} 
                                >
                    
                                </WordDefinition>
                            )
                            :
                            `No definition found for "${searchItem}".`
                         }

                    


                    </div>
                </div>
              </div>

            </div>
    
        );
        

        
}
export  default  EnDictionary;