import React from "react";

export default function Permissions(){
    return (
        <>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
            <h2><b>Permissions</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                
                  <p>
                  The books available for download on this website come from various sources. 
                  We have selected the books and organized them in categories so you can easily find the book you seek. 
                  Since We have no intention to violate copyrights and illegally distribute the hard work of authors, we only select eBooks that are in the public domain in the US. 
                  Nobody can grant or withhold permission to distribute those books. 
                  <a className="text-blue-400 hover:text-blue-600" href="https://www.gutenberg.org/policy/permission.html" target="_blank" rel="noreferrer"> Learn more here</a>.
                  </p>

                  <p>For more information, please get in touch with us by email at <a className="text-blue-400 hover:text-blue-600" href="mailto:contact@arfusoft.com">contact@arfusoft.com</a> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
    )
}