import {React,  useState, useEffect} from "react";
import { useParams } from 'react-router-dom';



function IsMobile() {
  var r = new RegExp("Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini");
  return r.test(navigator.userAgent);
}

//https://www.aspsnippets.com/Articles/3550/Solved-HTML-embed-Tag-not-displaying-PDF-file-in-Mobile-Browsers/
function DisplayMobile({file_url}){
  var pdfjsLib = window['pdfjs-dist/build/pdf'];
            pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';
            var pdfDoc = null;
            var scale = 1; //Set Scale for Zoom.
            var resolution = IsMobile() ? 1.5 : 1; //Set Resolution as per Desktop and Mobile.
            function LoadPdfFromUrl(url) {
              //Read PDF from URL.
              pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
                  pdfDoc = pdfDoc_;
       
                  //Reference the Container DIV.
                  var pdf_container = document.getElementById("pdf_container");
                  pdf_container.style.display = "block";
                  pdf_container.style.height = IsMobile() ? "1200px" : "820px";
       
                  //Loop and render all pages.
                  for (var i = 1; i <= pdfDoc.numPages; i++) {
                      RenderPage(pdf_container, i);
                  }
              });
          };

          function RenderPage(pdf_container, num) {
            pdfDoc.getPage(num).then(function (page) {
                //Create Canvas element and append to the Container DIV.
                var canvas = document.createElement('canvas');
                canvas.id = 'pdf-' + num;
                var ctx = canvas.getContext('2d');
                pdf_container.appendChild(canvas);
     
                //Create and add empty DIV to add SPACE between pages.
                var spacer = document.createElement("div");
                spacer.style.height = "20px";
                pdf_container.appendChild(spacer);
     
                //Set the Canvas dimensions using ViewPort and Scale.
                var viewport = page.getViewport({ scale: scale });
                canvas.height = resolution * viewport.height;
                canvas.width = resolution * viewport.width;
                   
                //Render the PDF page.
                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport,
                    transform: [resolution, 0, 0, resolution, 0, 0]
                };
     
                page.render(renderContext);
            });
        };

        useEffect(() => {
          LoadPdfFromUrl(file_url);
        }, [file_url])


        return (
          <div  className="py-12">
          <hr />
          <div id="pdf_container" style = {{height:"1000px", width:"100%"}}></div>
        </div>
        );
}

//https://dev.to/stephengade/how-to-display-pdf-in-react-or-vuejs-application-using-pdfjs-4d6c

//viewer.js line 1634
//const HOSTED_VIEWER_ORIGINS = ["null", "http://mozilla.github.io", "https://mozilla.github.io", "https://popularlibrary.com", "http://localhost:3000"];
//add your viewer origins
function BookDisplay (){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
            // Get a specific parameter
            let { uuid } = useParams();

    useEffect(() => {
        //const fileID = parseInt(id);

        //console.log(id)
        const url = `https://goldfish-app-asx3v.ondigitalocean.app/books/${uuid}`;
        fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);


          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )



      }, [uuid])// <-- }, [])  empty array means 'run once'






      if (error) {
        return <div><br /><br /><br />Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div><br /><br /><br />Loading...</div>;
      } else {
        const pdfUrl =  items.FileName;
        
      return(
       <div>
            
            { IsMobile()? 
              <DisplayMobile file_url={pdfUrl} />
              :                  
              <div  className="py-16"><iframe title="PDF" src={pdfUrl} width={"100%"} height={"1000px"}></iframe></div>
            }


            
       </div>


    );}



}

export  {BookDisplay};