import React, {useState} from "react";
//import HomeFooter from "./HomeFooter";
import librarian from "../../assets/images/young-librarian.jpg";
import bookshelf from "../../assets/images/old-bookshelf.jpg";
import roadmap from "../../assets/images/roadmap.jpg";
import contact from "../../assets/images/contactus.jpg";





export default function Hero (){
    return (
        <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-4 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      
    <div
        className="animate-[gradient_6s_linear_infinite]  pb-5 font-nacelle text-xl font-semibold text-transparent md:text-2xl"
        data-aos="fade-up">
          <h1><span className="text-indigo-500">Knowledge is power</span></h1>
                
    </div>
    <p className="text-xl text-gray-500 dark:text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200">Learning  widens your horizons and makes you a better and successful person.</p>
    <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center w-full">
        <div data-aos="fade-up" data-aos-delay="400">
        <a className="btn text-white bg-sky-600 hover:bg-sky-700 w-full mb-4 sm:w-auto sm:mb-0" href="/books">Get started</a>
        </div>
        <div data-aos="fade-up" data-aos-delay="600">
        <a className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="/about">Learn more</a>
        </div>
    </div>
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-full">
      <img className="object-cover object-center rounded" alt="hero" src={librarian}/>
    </div>
  </div>
</section>
    )
}