import React from "react";
import logo from "../assets/images/librarylogor.png";

function Navbar() {
    return (

        <div className="navbar navbar-glass navbar-sticky">
        <div className="navbar-start w-40">
            <a href="/"><img src={logo} width={40} height={40} alt="logo"></img></a>
            

        </div>
        <div className="navbar-center">
            <h5 className="font-extrabold text-transparent text-lg bg-clip-text bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400">Library of the People</h5>
        </div>
        <div className="navbar-end w-20">
            <div className=" ">
                <div className="dropdown-container">
                    <div className="dropdown">
                    <label className="flex cursor-pointer px-0 " tabIndex="0">
                    <svg className="dark:text-white" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Menu / Menu_Alt_05">
                            <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                    </label>
                    <div className="dropdown-menu dropdown-menu-bottom-left  w-46 ">

                        <a href="/" className="px-2 py-1 text-left text-base">
                         Home
                        </a>
                        <div className="divider my-0"></div>
                        <a href="/books" className="px-2 py-1 text-left text-base">
                         Books
                        </a>
                        <a href="/tutorials" className="px-2 py-1 text-left text-base">
                          Tutorials
                        </a>
                        <a href="/quizzes" className="px-2 py-1 text-left text-base">
                         Quizzes
                        </a>
                        <div className="divider my-0"></div>
                        <a href="/utilities/summarizer" className="px-2 py-1 text-left text-base">
                          Summarizer
                        </a>
                        <a href="/utilities/dictionary" className="px-2 py-1 text-left text-base">
                          English dictionary 
                        </a>
                        <a href="/utilities/dictionaries" className="px-2 py-1 text-left text-base">
                          Bilingual dictionaries
                        </a>
                        <a href="/utilities/rhymes" className="px-2 py-1 text-left text-base">
                          Rhymes Finder
                        </a>

                        <div className="divider my-0"></div>

                        {/*<a className="px-2 py-1 text-left  text-orange-500 text-base" href="/donate">
                         Donate
                        </a>

                        <div className="py-2 ">
                        <a className="btn btn-rounded btn-solid-primary w-full text-base" href="/login"> Login </a>
                        </div>
                        <a className="btn btn-rounded btn-primary text-base" href="/signup">  Register </a> */}
                        <div className="divider my-0"></div>

                    </div>

                    </div>
                </div>
            </div>

        </div>
        </div>





    );
  }
  export default Navbar;